import { Icon, IconSize } from '@icons/icons';
import React from 'react';

export const renderStars = (rating: number, iconSize: keyof typeof IconSize) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  return (
    <div className="flex">
      {Array.from({ length: fullStars }).map((_, index) => (
        <Icon key={index} name="StarIcon" iconColor="yellow" size={iconSize} />
      ))}
      {hasHalfStar && (
        <Icon name="StarHalfIcon" iconColor="yellow" size={iconSize} />
      )}
    </div>
  );
};
