'use client';

import { Container } from '@ui/components/ui';
import { LandingSearchCard } from '../search-card/landing-search-card';
import aiportIllustration from '../../../public/assets/heroSectionIllustration.svg';
import CurveBackground from '../../../public/assets/shapeIllustrationLandingPage.svg';
import Image from 'next/image';
import { Icon } from '@icons/icons';
import { StickyArrow } from '@ui/components/ui/arrow-down';
import { useTranslations } from 'next-intl';
import { type SearchAirportFieldsFragment } from '../../../graphql/generated/graphql';
import { renderStars } from '../../../helpers/render-starts-icon';

export const TopContent = ({
  airports,
  host,
}: {
  airports: Array<SearchAirportFieldsFragment>;
  host: string;
}) => {
  const t = useTranslations('top_content');

  const features = [
    {
      id: 1,
      icon: <Icon name="VerifiedUserOutlinedIcon" iconColor="default" />,
      text: t('features.free_cancellation'),
    },
    {
      id: 2,
      icon: <Icon name="NoCrashOutlinedIcon" iconColor="default" />,
      text: t('features.inspected_parkings'),
    },
    {
      id: 3,
      icon: <Icon name="BestPriceIcon" iconColor="default" />,
      text: t('features.best_price'),
    },
  ];

  return (
    <>
      <Container fullWidth>
        <div className="absolute h-[55rem] lg:h-[50rem] w-full left-0 top-0 -z-10 overflow-hidden">
          <div className="relative w-full h-full">
            <Image
              className="object-cover"
              src={CurveBackground}
              alt="Roosh"
              layout="fill"
              loading="lazy"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-0">
          <div className="lg:col-span-7 mt-2 lg:mt-20 z-10 pr-0 md:pr-10">
            <span className="text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-medium text-white">
              {t('hero_title')} {host}
            </span>

            <div className="flex justify-start items-center py-5">
              {renderStars(4.5, 8)}
              <span className="pl-2 text-2xl lg:text-3xl text-white">
                {t('rating')}
              </span>
            </div>

            <div className="mt-52 xl:mt-64 hidden lg:block absolute">
              {features.map(feature => (
                <div key={feature.id} className="flex items-center gap-3 mb-5">
                  <div className="bg-secondaryBackground p-2 rounded-xl">
                    {feature.icon}
                  </div>
                  <span className="text-xl font-normal">{feature.text}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:col-span-5 mt-5 lg:mt-20 flex justify-center lg:justify-end">
            <div className="max-w-sm lg:max-w-[28rem] w-full absolute px-2 md:px-0">
              <LandingSearchCard airports={airports} />
            </div>
          </div>
        </div>
      </Container>
      <div className="relative w-full pt-[28rem] lg:pt-[18rem]">
        <div className="relative top-0 sm:min-w-[20rem] overflow-hidden pt-24 lg:pt-10">
          <StickyArrow />
          <Image layout="responsive" alt="Roosh" src={aiportIllustration} priority />
        </div>

        <div className="bg-primaryBackground p-6 block lg:hidden">
          <h2 className="text-primaryText text-xl font-medium mb-4 sm:text-left lg:hidden">
            {t('why_choose')} {host}?
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 justify-start sm:justify-center">
            {features.map(feature => (
              <div
                key={feature.id}
                className="flex items-center gap-3 justify-start sm:justify-center py-1"
              >
                <div className="bg-secondaryBackground p-2 lg:p-3 rounded-xl">
                  {feature.icon}
                </div>
                <span className="text-primaryText text-lg">{feature.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
